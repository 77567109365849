<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row class="justify-center d-flex">
        <v-col lg="10" class="mx-auto">
          <v-expansion-panels flat color="#ddd">
            <v-expansion-panel
              v-for="panel in panels"
              :key="panel.title"
              class="bg-transparent"
            >
              <v-expansion-panel-header>
                <h5 class="text-h5 font-weight-bold text-typo">
                  {{ panel.title }}
                </h5>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p class="text-body text-sm opacity-8">
                  {{ panel.description }}
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "requester-faq",
  data() {
    return {

    };
  },
  computed:{
    panels(){
      return [
        {
          title: this.$translator.trans("I need guidance on how to use the platform, who do I contact?"),
          description:
      this.$translator.trans("Please send an email to contact@thepathforward.help;"),
        },
        {
          title: this.$translator.trans("How do I communicate with the volunteers?"),
          description:
          this.$translator.trans("Please use the “Messages” section to communicate with your assigned volunteers;"),
        },
        {
          title: this.$translator.trans("What should I not share?"),
          description:
          this.$translator.trans("We recommend not sharing private information such as credit card or other confidential data;"),
        },
        {
          title: this.$translator.trans("What do I do if someone requests money?"),
          description:
          this.$translator.trans("If the volunteers requests money please kindly refuse! We do not encourage exchange of money trough the platform;"),
        },
      ];
    }
  }
};
</script>
